<template>
    <v-dialog
        :max-width="'500px !important'"
        persistent
        v-model="cancelDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ $t('message.cancelLhdnInvoice') + ': ' + invoiceNumber }}</span>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="cancelForm" v-model="validForm">
                    <v-layout row pt-3>
                        <p class="black--text pl-5 mt-3 pb-0">Cancellation is permitted within the 72-hour window from the date and time the document was marked as valid.</p>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.cancelReason') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-lazy>
                                <v-combobox
                                    :items="reasonOptions"
                                    :loading="loading.reasonOptions"
                                    :placeholder="$t('message.selectOptionOrFreeText')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :value="cancel_reason"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="cancel_reason = $event"
                                ></v-combobox>
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="cancelDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-btn
                    :loading="loading.proceed"
                    color="info"
                    small
                    @click="cancelDocument()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {api} from "Api";

export default {
    name: "CancelDocument",
    props: ['dialog', 'invoiceNumber','jobId','documentUuid'],
    data() {
        return {
            cancel_dialog: false,
            cancel_reason: null,
            reasonOptions: [],
            loading: {
                proceed: false,
                reasonOptions: false
            },
            validForm: true,
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        cancelDialog: {
            get() {
                return this.cancel_dialog;
            },
            set(value){
                this.cancel_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        async cancelDocument() {
            if(this.$refs.cancelForm.validate()) {
                if (await this.$root.$confirm(this.$t('message.cancelLhdnInvoice') + ' ' + this.invoiceNumber, this.$t('message.confirmations.continueLhdnDeleteAction'), {color: 'orange'})) {
                    this.loading.proceed = true;
                    api
                        .put('/lhdn/document/cancel/' + this.jobId, {
                            cancel_reason: this.cancel_reason,
                            uuid: this.documentUuid
                        })
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.$toast.success(this.$t('message.successes.lhdnCancelled'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.proceed = false;
                                this.$emit('cancel-done')
                                this.cancelDialog = false;
                            } else {
                                this.$toast.error(this.$t('message.errors.lhdnNotCancelled'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.proceed = false;
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.lhdnNotCancelled'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.proceed = false;
                        })
                }
            } else {
                this.$toast.error(this.$t('message.errors.lhdnNotCancelled'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getReasonOptions() {
            this.loading.reasonOptions = true
            api
                .get('/lhdn/reason-options')
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.reasonOptions = response.data.data
                    } else {
                        console.log('Reason options cannot be loaded')
                    }
                    this.loading.reasonOptions = false
                })
                .catch(() => {
                    console.log('Reason options cannot be loaded')
                    this.loading.reasonOptions = false
                })
        }
    },
    watch: {
        dialog(value) {
            if(value) {
                this.cancel_reason = null;
                this.getReasonOptions()
            }
            this.cancel_dialog = value;
        }
    },
}
</script>

<style scoped>

</style>